/* @font-face {
    font-family: 'DINPro';
    src: url('/assets/fonts/dinpro.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
} */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: Tahoma, Arial, Helvetica, sans-serif;
	scrollbar-gutter: auto;
	letter-spacing: 0.5px;
}

@font-face {
    font-family: 'DINPro';
    src: url('./../public/assets/fonts/dinpro.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Caecilia LT Pro';
	src: url('./../public/assets/fonts/CaeciliaLTPro55Roman.TTF') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	background-color: var(--lightGrey);
	overflow-x: hidden;
	overflow-y: auto;
	font-size: clamp(0.1rem, 0.9vw, 0.8rem);
}

:root {
	--colorToken_brand: #006E87;
	--white: hsl(0, 0%, 100%);
	--lightGrey: hsl(0, 0%, 95%);
	--darkGrey: #24304F;
}

.App {
	width: 100vw;
    width: 100%;
    padding: 30px;
    background-color: #EAEBEF;
}

.grid {
	display: flex;
	flex-direction: column; /* Default to column layout for mobile */
	gap: 0.5rem;
	padding: 30px;
	height: auto;
	background-color: #ffffffb3;
	border-radius: 0 0 25px 25px;
}

.row1,
.row2,
.row3 {
	display: flex;
	flex-direction: column; /* Default to vertical stacking */
	gap: 0.5rem;
	width: 100%; /* Allow rows to take full width */
}
.row1:first-child,
.row2:first-child,
.row3:first-child  { margin-top: 0;}
.row2 {justify-content: space-between;}
.headerBar {
	background-color: var(--white);
	width: 100%;
	padding: 0 2rem;
}

.headerBar img {
	width: 100px;
	height: auto;
}

/* Table Styles */
.scrollableTable {
	flex: 1; /* Takes up remaining space */
	max-height: 400px; /* Adjust to fit your layout */
	overflow-y: auto; /* Vertical scroll for long content */
	overflow-x: auto; /* Horizontal scroll for wide tables */
	width: 100%; /* Ensure the table fits within its container */
}

table {
	width: 100%; /* Table should fill the container */
	table-layout: fixed; /* Prevents expansion beyond the column width */
	overflow-wrap: break-word; /* Forces content to break if it's too long */
	border-collapse: collapse; /* Ensures borders don’t double up */
}

th, td {
	padding: 8px;
	border: 1px solid var(--darkGrey); /* Border for table cells */
	text-align: left; /* Align text to the left */
}

tr:nth-child(even) {
	background-color: var(--darkGrey);
}

tr:nth-child(odd) {
	background-color: var(--white);
}

/* Paragraph Styles */
p {
	font-size: clamp(0.1rem, 1vw, 1rem);
}

/* Stat Group Styles */
.statGroup {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

/* Breakpoints for larger screens */
@media (min-width: 850px) {
	.grid {
		flex-direction: row; /* Change to row layout for tablet and larger screens */
	}

	.row1 {
		width: 40%; /* Maintain original width for larger screens */
		flex-direction: column; /* Keep column layout */
	}

	.row2 {
		width: 20%; /* Maintain original width for larger screens */
		flex-direction: column; /* Keep column layout */
	}

	.row3 {
		width: 40%; /* Maintain original width for larger screens */
		flex-direction: column; /* Keep column layout */
	}
}

@media (min-width: 850px) {
	.row1, .row2, .row3 {
		flex-direction: column; /* Stack vertically on larger screens */
	}
}
