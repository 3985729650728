.statContainer {
	align-items: center;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	> p {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	/* box-shadow: -1px 1px 4px 0px black; */
}

.innerContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.title {
	/* position: absolute; */
	background-color: var(--colorToken_brand);
	color: var(--white);
	top: 0;
	/* height: 1.5rem; */
	text-align: center;
	padding: 0.25rem;
	font-size: 1rem;
}

.name {
	padding: 0.5rem 0 0 0;
	font-size: 1.25rem;
	text-align: center;
}

.stat {
	color: var(--colorToken_brand);
	padding: 0.5rem 1rem;
	font-size: 1.5rem;
	font-weight: bold;
}
