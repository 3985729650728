.tableContainer {
	max-height: 200px; /* Set max height for the table */
	overflow-y: auto;  /* Enable vertical scroll */
	overflow-x: hidden; /* Disable horizontal scroll */
	border: 1px solid #ddd;
	border-radius: 4px;
  }
  
  .table {
	width: 100%;        /* Ensure the table spans full width */
	border-collapse: collapse;
	table-layout: fixed; /* Ensure columns are responsive */
  }
  
  .tableHeader {
	position: sticky;    /* Stick the header at the top */
	top: 0;
	background-color: #f1f1f1;
	z-index: 1;
  }
  
  .tableHeader th {
	padding: 10px;
	border: 1px solid #ddd;
	text-align: center;
	background-color: var(--colorToken_brand);
	color: var(--white);
	font-weight: bold;
  }
  
  .tableBody tr:nth-child(even) {
	background-color: #f9f9f9;
  }
  
  .tableBody tr:hover {
	background-color: #eaeaea;
  }
  
  .tableBody td {
	padding: 10px;
	border: 1px solid #ddd;
	text-align: center;
	word-wrap: break-word; /* Ensure text wraps within cells */
	white-space: normal;   /* Allow text wrapping */
  }
  
  .center {
	text-align: center;
  }
  
  @media (max-width: 768px) {
	.tableContainer {
	  max-height: 250px; /* Adjust for smaller screens */
	}
  
	.tableHeader th, .tableBody td {
	  font-size: 12px;
	  padding: 8px;
	}
  }
