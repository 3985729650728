/* Header Styling */
button {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.header {
    flex-direction: column;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background-color: #ffffff; /* Blue */
    color: #8287A1;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    border-radius: 25px 25px 0 0;
}

/* Left Section - Logo */
.headerLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    padding:20px;
}

/* Center Section - Course Title */
.courseTitle {
    flex-grow: 1;
    text-align: center;
    /* font-size: 1.5rem;
    font-weight: bold;
    font-family: "DINPro";
font-weight: 700;
font-size: 16px;
line-height: 28px;
letter-spacing: -2%;
color: #646F84; */
font-family: "Caecilia LT Pro", sans-serif;
font-weight: 700;
font-size: 42px;
line-height: 160%;
letter-spacing: 0%;
text-align: center;
color: #26BCD7;
}

/* Right Section - Dropdown & User Info */
.headerRight {
    display: flex;
    align-items: end !important;
    gap: 12px;
    flex-direction: column;
}

/* User Info */
.userInfo {
    font-size: 0.9rem;
}

/* Dropdown Select */
.courseSelect {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #D9DBE3;
    color: #646F84;
    font-size: .8rem;
    height: 60px;
    border-right: 20px solid #D9DBE3;
}

/* Focus effect */
.courseSelect:focus {
    /* border-color: #3b82f6;
       box-shadow: 0 0 5px rgba(59, 130, 246, 0.5); */
}

/* Logout Button */
.logoutButton {
    padding: 8px 12px;
    background-color: #D9DBE3; /* Red */
    color: #646F84;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.logoutButton:hover {
    background-color: #26BCD7; /* Darker Red */
    color: #ffffff;
}

.header {
    display: flex;
    justify-content: space-between; /* Distributes columns evenly */
    align-items: center;
}

.headerLeft, .headerCenter, .headerRight {
    flex: 1; /* Equal width for all three columns */
    display: flex;
    justify-content: center; /* Center content inside each column */
    align-items: center;
    padding: 10px;
}

.headerCenter {
    align-items: center;
    font-family: "DINPro";
    font-weight: 700;
    font-size: 8px;
    line-height: 28px;
    letter-spacing: -2%;
    flex-direction: column;
    padding: 30px 0;
}

.logo {
    max-width: 150px; /* Adjust logo size as needed */
    height: auto;
}

.courseSection {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Caecilia LT Pro";
font-weight: 700;
font-size: 42px;
line-height: 160%;
letter-spacing: 0%;
text-align: center;
color: #26BCD7;
background-color: rgba(255,255,255, 0.7);
width: 100%;
padding: 50px;
}

/* Apply flex-direction column below 800px */
@media screen and (max-width: 850px) {
    .header {
        flex-direction: column; /* Stack items vertically */
    }

    .headerContainer {
        flex-direction: column; /* Stack items vertically */
        align-items: center;    /* Center items horizontally */
    }

    .headerLeft {
        justify-content: center; /* Center the logo */
        padding: 30px 20px 0 20px !important
    }

    .headerRight {
        flex-direction: column; /* Stack the right section vertically */
        align-items: center;
    }

    .courseTitle {
        font-size: 30px; /* Adjust font size on smaller screens */
    }
}